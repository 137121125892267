@use "@angular/material" as mat;

@include mat.core();

$md-primary: (
  50: #e7e7e8,
  100: #c2c4c6,
  200: #9a9da0,
  300: #717579,
  400: #52585d,
  500: #343a40,
  600: #2f343a,
  700: #272c32,
  800: #21252a,
  900: #15181c,
  A100: #63b1ff,
  A200: #3097ff,
  A400: #007efc,
  A700: #0071e2,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$md-accent: (
  50: #ebeef0,
  100: #cdd4da,
  200: #acb7c2,
  300: #8b9aa9,
  400: #728596,
  500: #596f84,
  600: #51677c,
  700: #485c71,
  800: #3e5267,
  900: #2e4054,
  A100: #a1ccff,
  A200: #6eb0ff,
  A400: #3b95ff,
  A700: #2187ff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  ),
);

$md-warn: (
  50: #fbe5e5,
  100: #f5bebe,
  200: #ee9393,
  300: #e76767,
  400: #e14747,
  500: #dc2626,
  600: #d82222,
  700: #d31c1c,
  800: #ce1717,
  900: #c50d0d,
  A100: #fff1f1,
  A200: #ffbebe,
  A400: #ff8b8b,
  A700: #ff7272,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$limpieza-primary: mat.define-palette($md-primary);
$limpieza-accent: mat.define-palette($md-accent);
$limpieza-warn: mat.define-palette($md-warn);

$limpieza-theme: mat.define-light-theme(
  (
    color: (
      primary: $limpieza-primary,
      accent: $limpieza-accent,
      warn: $limpieza-warn,
    ),
  )
);

@include mat.all-component-themes($limpieza-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  overflow-y: scroll;
  background: #b6d4ff;
  box-shadow: inset 0 0 4px #707070;
  border-radius: 30px;
}

::-webkit-scrollbar-thumb {
  background: #092957;
  border-radius: 30px;
}